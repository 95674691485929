import { getProductGlobalId, storefrontClient } from '../../utils/shopify'

const PRODUCT_FRAGMENT = `
id
title
productType,
tags,
bundleProductIds: metafield(namespace: "products", key: "bundle") {
  value
}
imageShopMobile: metafield(namespace: "products", key: "image_shop_mobile") {
  value
}
imageShopDesktop: metafield(namespace: "products", key: "image_shop_desktop") {
  value
}
imageShopDesktopHover: metafield(namespace: "products", key: "image_shop_desktop_hover") {
  value
}
slug: metafield(namespace: "products", key: "slug") {
  value
}
sellingPlanGroups(first:1) {
  nodes {
    name
    sellingPlans(first:10) {
      nodes {
        id
        name
        options {
          name
          value
        }
      }
    }
  }
}
priceRange {
  maxVariantPrice {
    amount
    currencyCode
  }
  minVariantPrice {
    amount
    currencyCode
 }
}
featuredImage {
  altText
  url
  height
  width
}
variants(first:50) {
  nodes {
    id
    price {
      amount
      currencyCode
    }
    priceV2 {
      amount
      currencyCode
    }
    compareAtPriceV2 {
      amount
      currencyCode
    }
    sku
    quantityAvailable,
    selectedOptions {
      name
      value
    }
    sellingPlanAllocations(first:10) {
      nodes {
        sellingPlan {
          id
          name
        }
      }
    }
  }
}`

const PRODUCT_QUERY = `
  query product($id: ID!) {
    product(id: $id) {
      title
      productType
      sellingPlanGroups(first:1) {
        nodes {
          name
          sellingPlans(first:10) {
            nodes {
              id
              name
              options {
                name
                value
              }
            }
          }
        }
      }
      variants(first:10) {
        nodes {
          id
          price {
            amount,
            currencyCode
          }
          priceV2 {
            amount,
            currencyCode
          }
          compareAtPrice {
            amount,
            currencyCode
          }
          compareAtPriceV2 {
            amount,
            currencyCode
          }
          sku
          quantityAvailable,
          selectedOptions {
            name
            value
          }
          sellingPlanAllocations(first:10) {
            nodes {
              sellingPlan {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
const handleFetchShopifyProduct = async productId => {
  const { data } = await storefrontClient.request(PRODUCT_QUERY, {
    variables: { id: getProductGlobalId(productId) }
  })

  return data?.product ?? null
}

function getProducts(query) {
  return storefrontClient
    .request(
      `{
    products(first: 50, query: "${query}") {
      nodes {
        ${PRODUCT_FRAGMENT}
      }
    }
  }`
    )
    .then(result => result.data.products.nodes)
}

function getShopPageMetaobject() {
  return storefrontClient
    .request(
      `{
    metaobject(handle:{type:"shop_page", handle:"shop_page"}) {
      addons: field(key:"one_time_addons") {
        references(first: 50) {
          nodes {
            ...on Product {
              ${PRODUCT_FRAGMENT}
            }
          }
        }
      }
    }
  }`
    )
    .then(result => result.data.metaobject)
}

export { getProducts, getShopPageMetaobject }
