import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getShopPageMetaobject } from 'services/shopify/query.js'
import { useDispatch, useSelector } from 'react-redux'
import provenPay from 'services/proven-pay'
import {
  DAY_EYE_CREAM_PRODUCT,
  NIGHT_EYE_CREAM_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  NIGHT_CREAM_PRODUCT,
  CLEANSER_PRODUCT,
  EYE_CREAM_PRODUCT_TAG,
  SERUM_PRODUCT,
  SERUM_PRODUCT_TAG,
  SYSTEM_PRODUCT_TAG
} from 'constants/products'
import {
  AnalyticsEventName,
  ShopifySalesChannel,
  getClientBrowserParameters,
  sendShopifyAnalytics,
  useCart
} from '@shopify/hydrogen-react'
import { productsUrl } from 'constants/endpoints'
import { getInitialProductVariantId, defaultShopifyAnalyticsData } from './util'
import { openCart } from 'actions/cart.actions'
import { useAuthContext } from 'providers/AuthProvider'
import { shopifyLoginUrl } from 'constants/endpoints'

function useShopifyLoggedInUrl(returnUrl) {
  let { jwtToken } = useAuthContext()
  let url = new URL(shopifyLoginUrl)
  url.searchParams.set('token', jwtToken)
  url.searchParams.set('return_to_uri', returnUrl)
  return url
}

function useSubscriptions() {
  const user = useSelector(state => state.account?.info)
  return (
    useQuery({
      queryKey: ['subscriptions', user?.email],
      queryFn: () => provenPay.get('subscriptions').then(result => result.data),
      enabled: Boolean(user?.email)
    }).data ?? []
  )
}

function usePastOrders() {
  const user = useSelector(state => state.account?.info)
  return useQuery({
    queryKey: ['past-orders', user?.email],
    queryFn: () => provenPay.get('past-orders').then(result => result.data)
  }).data
}

function useUpcomingOrders() {
  let { data: allProducts } = useAllProducts()
  let subscriptionContracts = useSubscriptions()
  return subscriptionContracts.map(contract => {
    let itemsWithProducts = contract.items.map(someItem => {
      let product = allProducts.find(someProduct => someProduct.id === someItem.variant.product.id)
      let priceWithDiscount = someItem.priceWithoutDiscount
      if (someItem.discounts[0]?.fixedValue) {
        priceWithDiscount -= someItem.discounts[0].fixedValue
      } else if (someItem.discounts[0]?.percentage) {
        priceWithDiscount -= priceWithDiscount * someItem.discounts[0].percentage
      }
      return { ...someItem, priceWithDiscount, product }
    })
    return {
      contract,
      items: itemsWithProducts,
      total: Number(
        itemsWithProducts
          .reduce((acc, someItem) => acc + someItem.priceWithoutDiscount, 0)
          .toFixed(2)
      ),
      totalWithDiscounts: Number(
        itemsWithProducts.reduce((acc, someItem) => acc + someItem.priceWithDiscount, 0).toFixed(2)
      )
    }
  })
}

function useMainProductsNoBundles() {
  let { data: mainProducts } = useMainProducts()
  return mainProducts.filter(someProduct => !someProduct.bundleProductIds)
}

function useMainProductsWithoutSubscription() {
  let subscriptions = useSubscriptions()
  let products = useMainProductsNoBundles()
  return products.filter(someProduct => {
    return !subscriptions.some(someSubscription =>
      someSubscription.items.some(someItem => someProduct.id === someItem.variant.product.id)
    )
  })
}

function useSubscriptionMutations() {
  const mainProducts = useMainProducts()

  async function addBundleToSubscription({ product, sellingPlanId }) {
    const bundleProducts = product.bundleProductIds.map(someProductId =>
      mainProducts.data?.find(someProduct => someProduct.id === someProductId)
    )
    for (const bundleProduct of bundleProducts) {
      try {
        // wait for each mutation to finish
        const itemToAdd = {
          variantId: getInitialProductVariantId(bundleProduct),
          sellingPlanId
        }
        await provenPay.put('latest-subscription', {
          itemToAdd
        })
      } catch (error) {
        console.error(`Error in mutation for ${product}:`, error)
        break // Stop mutation execution in case of error
      }
    }
  }

  let addItem = useMutation({
    mutationFn(itemToAdd) {
      const product = itemToAdd.itemToAdd.product
      const sellingPlanId = itemToAdd.itemToAdd.sellingPlanId
      if (product.bundleProductIds) return addBundleToSubscription({ product, sellingPlanId })

      provenPay.put('latest-subscription', {
        itemToAdd: {
          variantId: getInitialProductVariantId(product),
          sellingPlanId
        }
      })
    }
  })
  let removeItem = useMutation({
    mutationFn(itemToRemove) {
      provenPay.put('latest-subscription', {
        itemToRemove
      })
    }
  })
  return { addItem, removeItem }
}

function useShopPageData() {
  return useQuery({
    queryKey: ['shop-page'],
    queryFn: getShopPageMetaobject
  })
}

function useMainProducts() {
  const user = useSelector(state => state.account?.info)
  return useQuery({
    queryKey: ['products/main', user?.email],
    queryFn: () => provenPay.get(productsUrl).then(result => result.data)
  })
}

function useAllProducts() {
  const { data: mainProducts = [] } = useMainProducts()
  const { data: shopProducts = [] } = useShopProducts()

  return { data: mainProducts.concat(shopProducts) }
}

function useMainProductById(productId) {
  let { data: products = [] } = useMainProducts()

  return products.find(product => product.id === productId)
}

function useSystem() {
  let mainProducts = useMainProducts()
  let systemProduct = mainProducts.data?.find(someProduct => someProduct.tags.includes('id:system'))
  return {
    ...mainProducts,
    data: systemProduct
  }
}

function useEyeCreamDuo() {
  let mainProducts = useMainProducts()
  let eyeCreamDuoProduct = mainProducts.data?.find(someProduct =>
    someProduct.tags.includes(EYE_CREAM_PRODUCT_TAG)
  )
  return {
    ...mainProducts,
    data: eyeCreamDuoProduct
  }
}

function useSerum() {
  let mainProducts = useMainProducts()
  let serumProduct = mainProducts.data?.find(someProduct =>
    someProduct.tags.includes(SERUM_PRODUCT_TAG)
  )
  return {
    ...mainProducts,
    data: serumProduct
  }
}

function useSystemProducts() {
  let mainProducts = useMainProducts()
  let systemProduct = mainProducts.data?.find(someProduct =>
    someProduct.tags.includes(SYSTEM_PRODUCT_TAG)
  )

  const systemProducts = systemProduct?.bundleProductIds.map(productId =>
    mainProducts?.data?.find(someProduct => someProduct.id === productId)
  )
  return {
    ...mainProducts,
    data: systemProducts
  }
}

function useEyeCreamDuoProducts() {
  let mainProducts = useMainProducts()
  let eyeCreamDuoProduct = mainProducts.data?.find(someProduct =>
    someProduct.tags.includes(EYE_CREAM_PRODUCT_TAG)
  )
  const eyeCreamDuoProducts = eyeCreamDuoProduct?.bundleProductIds.map(productId =>
    mainProducts?.data?.find(someProduct => someProduct.id === productId)
  )
  return {
    ...mainProducts,
    data: eyeCreamDuoProducts
  }
}

function useShopProducts() {
  const shopPageQuery = useShopPageData()
  return {
    ...shopPageQuery,
    data: shopPageQuery.data?.addons.references.nodes
  }
}

function withShopProducts() {
  return function withProductsWrapper(Component) {
    return function WithProductsComponent(props) {
      const products = useShopProducts()
      return <Component {...props} products={products} />
    }
  }
}

const getSubProductsFromBundle = (shopifyProductObject, products) => {
  const result = shopifyProductObject.bundleProductIds?.map(id => {
    return products.find(p => p.id === id)
  })
  return result
}

function getPeriodAndUnits(product) {
  const sellingPlan = product.sellingPlanGroups.nodes
    .at(0)
    ?.sellingPlans?.nodes.find(n => n.id === product.initialSellingPlanId)
  return {
    //   period: sellingPlan?.options.at(0).value,
    //   periodUnit: sellingPlan?.options.at(0).value
    sellingPlan: sellingPlan?.name
  }
}

function useSubscriptionsInfo() {
  const activeSubscriptions = useSubscriptions()
  const products = useMainProductsNoBundles()
  const activeSubscribedProducts = products
    .map(someProduct => {
      const subscription = activeSubscriptions?.find(someSubscription =>
        someSubscription.items.some(someItem => someProduct.id === someItem.variant.product.id)
      )
      return {
        product: someProduct,
        subscription
      }
    })
    .filter(someProduct => someProduct.subscription)
  return {
    activeSubscriptions,
    activeSubscribedProducts
  }
}

function useProductPrices(shopifyProductObject) {
  const { data: mainProducts } = useMainProducts()
  const {
    initialSellingPlanId,
    sellingPlanGroups: {
      nodes: [
        {
          sellingPlans: { nodes: sellingPlans }
        }
      ]
    }
  } = shopifyProductObject
  let defaultSellingPlan = sellingPlans.find(
    someSellingPlan => someSellingPlan.id === initialSellingPlanId
  )
  let {
    priceAdjustments: [{ adjustmentValue }]
  } = defaultSellingPlan
  let oneTimePriceObject = shopifyProductObject.bundleProductIds
    ? shopifyProductObject.priceRange.minVariantPrice
    : shopifyProductObject.variants.nodes.at(0).price
  let oneTimePriceString = oneTimePriceObject.amount
  let oneTimePrice = Number(oneTimePriceString)
  let listedPrice = oneTimePrice
  let subscriptionPriceString = adjustmentValue.adjustmentPercentage
    ? (listedPrice * (1 - adjustmentValue.adjustmentPercentage / 100)).toFixed(2)
    : adjustmentValue.price.amount
  if (shopifyProductObject.bundleProductIds) {
    const bundleProducts = shopifyProductObject.bundleProductIds.map(someId =>
      mainProducts.find(someProduct => someProduct.id === someId)
    )
    listedPrice = bundleProducts.reduce(
      (acc, currentProduct) => acc + Number(currentProduct.variants.nodes.at(0).price.amount),
      0
    )
    if (adjustmentValue.price && !adjustmentValue.adjustmentPercentage) {
      subscriptionPriceString = (
        adjustmentValue.price.amount * shopifyProductObject.bundleProductIds.length
      ).toFixed(2)
    } else {
      subscriptionPriceString = (
        listedPrice *
        (1 - adjustmentValue.adjustmentPercentage / 100)
      ).toFixed(2)
    }
  }
  let subscriptionPrice = Number(subscriptionPriceString)
  let currencyCode = oneTimePriceObject.currencyCode
  return {
    oneTimePrice,
    subscriptionPrice,
    listedPrice,
    bundleDiscount: listedPrice - oneTimePrice,
    subscriptionDiscount: oneTimePrice - subscriptionPrice,
    fullDiscount: listedPrice - subscriptionPrice,
    oneTimePriceString,
    subscriptionPriceString,
    currencyCode,
    sellingPlanStr: defaultSellingPlan.name
  }
}

function useCartActions() {
  const { linesAdd, linesRemove, lines, id: cartId } = useCart()
  const dispatch = useDispatch()
  const mainProducts = useMainProducts()

  function addIfMissing(cartLines) {
    let linesNotInCart = cartLines.filter(cartLine => {
      return !lines.some(someLine => {
        let merchandiseMatches = someLine.merchandise.id === cartLine.merchandiseId
        if (!cartLine.sellingPlanId) {
          return merchandiseMatches && someLine.sellingPlanAllocation === null
        }
        return (
          merchandiseMatches &&
          someLine.sellingPlanAllocation?.sellingPlan.id === cartLine.sellingPlanId
        )
      })
    })
    if (linesNotInCart.length === 0) {
      dispatch(openCart())
    }
    linesAdd(linesNotInCart)
  }

  function addBundle({ product, sellingPlanId }) {
    const bundleProducts = product.bundleProductIds.map(someProductId =>
      mainProducts.data?.find(someProduct => someProduct.id === someProductId)
    )
    return addIfMissing(
      bundleProducts.map(bundleProduct => ({
        merchandiseId: bundleProduct.variants.nodes[0].id,
        sellingPlanId
      }))
    )
  }

  /**
   * if pass no sellingPlanId mean is a one-time product
   * @param product
   * @param sellingPlanId
   */
  function add({ product, sellingPlanId }) {
    if (product.bundleProductIds) return addBundle({ product, sellingPlanId })

    // Track Cart Event
    sendShopifyAnalytics({
      eventName: AnalyticsEventName.ADD_TO_CART,
      payload: {
        ...getClientBrowserParameters(),
        ...defaultShopifyAnalyticsData,
        hasUserConsent: true,
        shopifySalesChannel: ShopifySalesChannel.headless,
        cartId,
        products: [
          {
            productGid: product.id,
            variantGid: getInitialProductVariantId(product),
            name: product.title
          }
        ]
      }
    })
    if (sellingPlanId) {
      return addIfMissing([
        {
          merchandiseId: product.variants.nodes[0].id,
          sellingPlanId
        }
      ])
    }
    return linesAdd({ merchandiseId: product.variants.nodes[0].id })
  }

  function clear() {
    linesRemove(lines.map(line => line.id))
  }

  function remove(cartLineId) {
    linesRemove([cartLineId])
  }

  return { add, clear, remove }
}

function useIsSystemSubscriber() {
  const { activeSubscribedProducts } = useSubscriptionsInfo()
  const { data: systemProducts } = useSystemProducts()
  return systemProducts.every(someProduct =>
    activeSubscribedProducts.some(
      ({ product: subscribedProduct }) => subscribedProduct.id === someProduct.id
    )
  )
}

function useIsEyeCreamDuoSubscriber() {
  const { activeSubscribedProducts } = useSubscriptionsInfo()
  const { data: eyeCreamDuoProducts } = useEyeCreamDuoProducts()
  return eyeCreamDuoProducts.every(someProduct =>
    activeSubscribedProducts.some(
      ({ product: subscribedProduct }) => subscribedProduct.id === someProduct.id
    )
  )
}

function useIsSubscribedToProductId(someProductId) {
  const { activeSubscribedProducts } = useSubscriptionsInfo()
  return activeSubscribedProducts.some(({ product: subscribedProduct }) =>
    subscribedProduct.tags.includes(`id:${someProductId}`)
  )
}

function useIsSerumSubscriber() {
  return useIsSubscribedToProductId(SERUM_PRODUCT)
}

function useIsDayEyeCreamSubscriber() {
  return useIsSubscribedToProductId(DAY_EYE_CREAM_PRODUCT)
}

function useIsNightEyeCreamSubscriber() {
  return useIsSubscribedToProductId(NIGHT_EYE_CREAM_PRODUCT)
}

function useIsNightCreamSubscriber() {
  return useIsSubscribedToProductId(NIGHT_CREAM_PRODUCT)
}

function useIsCleanserSubscriber() {
  return useIsSubscribedToProductId(CLEANSER_PRODUCT)
}

function useIsDayMoisturizerSubscriber() {
  return useIsSubscribedToProductId(DAY_MOISTURIZER_PRODUCT)
}

function useCartLines() {
  let cart = useCart()
  let { data: mainProducts } = useMainProducts()
  let bundles = mainProducts.filter(someProduct => someProduct.bundleProductIds)
  let bundlesWithSellingPlanInCart = bundles
    .map(someBundleProduct => {
      let cartLinesForBundle = cart.lines.filter(
        someCartLine =>
          someBundleProduct.bundleProductIds.includes(someCartLine.merchandise.product.id) &&
          someCartLine.sellingPlanAllocation?.sellingPlan.id ===
            someBundleProduct.initialSellingPlanId
      )
      let isBundleInCart = cartLinesForBundle.length === someBundleProduct.bundleProductIds.length
      if (!isBundleInCart) return false
      return {
        product: someBundleProduct,
        lines: cartLinesForBundle,
        sellingPlan: someBundleProduct.sellingPlanGroups.nodes[0].sellingPlans.nodes.find(
          somePlan => somePlan.id === someBundleProduct.initialSellingPlanId
        )
      }
    })
    .filter(Boolean)
  let individualProductsLinesWithSellingPlan = cart.lines.filter(
    someCartLine =>
      someCartLine.sellingPlanAllocation &&
      bundles.every(
        someBundleProduct =>
          someBundleProduct.initialSellingPlanId !==
          someCartLine.sellingPlanAllocation.sellingPlan.id
      )
  )
  return {
    withSellingPlan: {
      bundles: bundlesWithSellingPlanInCart,
      individualProductsLines: individualProductsLinesWithSellingPlan
    },
    oneTime: cart.lines.filter(someCartLine => !someCartLine.sellingPlanAllocation)
  }
}

export {
  useCartLines,
  useAllProducts,
  useCartActions,
  useEyeCreamDuo,
  useEyeCreamDuoProducts,
  useMainProductById,
  useMainProducts,
  useMainProductsWithoutSubscription,
  useSubscriptions,
  useProductPrices,
  useSerum,
  useShopProducts,
  useSubscriptionMutations,
  useSystem,
  useSystemProducts,
  withShopProducts,
  useSubscriptionsInfo,
  useUpcomingOrders,
  usePastOrders,
  useIsSystemSubscriber,
  useIsEyeCreamDuoSubscriber,
  useIsCleanserSubscriber,
  useIsNightCreamSubscriber,
  useIsDayMoisturizerSubscriber,
  useIsDayEyeCreamSubscriber,
  useIsNightEyeCreamSubscriber,
  useIsSerumSubscriber,
  useShopifyLoggedInUrl
}
