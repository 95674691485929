import {
  AnalyticsEventName,
  ShopifySalesChannel,
  getClientBrowserParameters,
  sendShopifyAnalytics
} from '@shopify/hydrogen-react'
import { STORE_ID } from 'constants/constants'

// Temporary Function to get inital plan id from shopify product
export function getInitialSellingPlanId(product) {
  if (product?.sellingPlanGroups?.nodes?.length > 0) {
    const sellingPlan = product?.sellingPlanGroups?.nodes[0]
    if (sellingPlan.nodes?.length > 0) {
      return sellingPlan.nodes.find(plan => plan?.name?.includes('8')) ?? sellingPlan.nodes[0]
    }
  }

  return undefined
}

export function getInitialProductVariantId(product) {
  if (product?.variants?.nodes?.length > 0) {
    return product?.variants?.nodes[0]?.id
  }

  return undefined
}

export function sendShopifyPageView(analyticsPageData) {
  const payload = {
    ...getClientBrowserParameters(),
    ...analyticsPageData,
    shopifySalesChannel: ShopifySalesChannel.headless,
    pageType: 'page'
  }

  sendShopifyAnalytics({
    eventName: AnalyticsEventName.PAGE_VIEW,
    payload
  })
}

export const defaultShopifyAnalyticsData = {
  shopId: `gid://shopify/Shop/${STORE_ID}`,
  currency: 'USD',
  acceptedLanguage: 'en'
}

export const checkSubscriptionType = (subscription, productsObj, type) => {
  return !!subscription?.items.find(item => productsObj[item.productId]?.productType === type)
}
