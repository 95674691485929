import { useShopifyCookies } from '@shopify/hydrogen-react'
import React, { useEffect } from 'react'
import { defaultShopifyAnalyticsData, sendShopifyPageView } from 'services/shopify/util'
import { history } from 'store/configureStore'

export default function AnalyticsPageViewsProvider({ children }) {
  useShopifyCookies({ hasUserConsent: true })

  useEffect(() => {
    const analytics = {
      hasUserConsent: true,
      ...defaultShopifyAnalyticsData
    }
    const handleRouteChange = () => {
      sendShopifyPageView(analytics)
    }

    const unlisten = history.listen(location => {
      console.log(`Route change complete to ${location.pathname}`)
      handleRouteChange()
    })

    return () => {
      unlisten()
    }
  }, [])

  return <>{children}</>
}
